<template>
  <v-container>
    <v-form ref="form" v-model="valid" class="mt-5">
      <k-field-group language-prefix="apiCredential.fields">
        <v-col cols="12" lg="6" md="8" offset-lg="3" offset-md="2" offset-sm="1" sm="10">
          <v-subheader class="pa-0">{{ $tc('apiCredential.title', 1) }}</v-subheader>
          <v-card>
            <v-card-text>
              <ClientAutocomplete field="client" v-model="values.client" required/>
              <KTextField v-model="values.name" field="name" required/>
              <KTextField v-model="values.maximumNumberOfTokens" field="maximumNumberOfTokens" required type="number" min="0"/>
            </v-card-text>
          </v-card>
        </v-col>
      </k-field-group>
    </v-form>
  </v-container>
</template>

<script lang="js">
import ApiCredential from '@/application/models/apiCredential.js';
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KTextField from '@/components/crud/fields/KTextField.vue';
import Form from '@/components/VuetifyResourceBaseForm.vue';
import ClientAutocomplete from '@/components/autocompletes/Client.vue';

export default {
  name: 'ClientForm',
  extends: Form,
  components: {
    ClientAutocomplete,
    KTextField,
    KFieldGroup,
  },
  data: () => ({
    values: new ApiCredential(),
  }),
};
</script>
