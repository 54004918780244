import { getPaginated } from '@/api/implementation/app/wrapper.js';
import { destroy, get, post, put } from '@/api/implementation/app';

function autocomplete(page, perPage, search, sortBy, descending, params) {
  return getPaginated('client/autocomplete', page, perPage, search, sortBy, descending, params);
}

function index(page, perPage, search, sortBy, descending, params) {
  return getPaginated('client', page, perPage, search, sortBy, descending, params);
}

function show(clientId) {
  return get(`client/${clientId}`);
}

function create(client) {
  return post('client', client);
}

function update(client) {
  return put(`client/${client.id}`, client);
}

function remove(clientId) {
  return destroy(`client/${clientId}`);
}

export {
  autocomplete,
  index,
  show,
  create,
  update,
  remove,
};
